import { createContext, useReducer, useContext } from 'react';

import { AppState, AppDispatch, ActionTypes, AppProviderProps } from './types';

const AppStateContext = createContext<AppState | undefined>(undefined);
const AppDispatchContext = createContext<AppDispatch | undefined>(undefined);

function appReducer(state: AppState, action: ActionTypes) {
  switch (action.type) {
    case 'set-current-user': {
      return {
        ...state,
        currentUser: action.data,
      };
    }
    case 'set-brands': {
      return {
        ...state,
        brands: action.data,
      };
    }
    case 'add-alert': {
      return {
        ...state,
        alerts: state.alerts.concat(action.data),
      };
    }
    case 'remove-alert': {
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.id !== action.data.id),
      };
    }
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, {
    currentUser: undefined,
    brands: [],
    alerts: [],
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

export function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return context;
}
