import { Breadcrumb, BreadcrumbItem } from '@fe/breadcrumb';
import { Button, ButtonGroup } from '@fe/button';
import { PageLayout } from '@fe/layout';
import { ReactNode, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppState } from '../app-context';
import { getUrlFromPartition } from '../constants';
import { UserDetails } from '../types';
import { findBrand } from './utils';

const makeBreadcrumbItems = (
  merchantId: string | undefined,
  partitionUrl: string
) => [
  {
    label: merchantId!,
    href: `${partitionUrl}v2/app/merchant-settings`,
  },
  {
    label: 'Users',
  },
];

const makeButtonGroupItems = (currentUser?: UserDetails) => {
  const adminButtonItem = (currentUser?.type === 'INTERNAL' && {
    value: 'admins',
    content: 'Admins',
  }) as {
    value: string;
    content: string;
  };

  return [
    {
      value: 'users',
      content: 'Users',
    },
    adminButtonItem,
  ].filter(el => el);
};

export const MerchantUserHeader = ({ children }: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const { currentUser, brands } = useAppState();

  const getBrandPartition = () =>
    currentUser?.type === 'INTERNAL'
      ? findBrand(brands, merchantId!)?.brandPartition
      : currentUser?.brandRoles.find(b => b.brandId === merchantId)?.partition;

  const brandPartition = getBrandPartition();
  const partitionUrl = brandPartition
    ? getUrlFromPartition(brandPartition)
    : '';

  const breadcrumbItems: BreadcrumbItem<string>[] = useMemo(
    () => makeBreadcrumbItems(merchantId, partitionUrl),
    [merchantId, partitionUrl]
  );

  const buttonGroupItems = makeButtonGroupItems(currentUser);

  const handleButtonGroupItemClick = (value: string) => {
    switch (value) {
      case 'admins': {
        return navigate('/admin/users');
      }
      default:
        return;
    }
  };

  return (
    <PageLayout.Header>
      <div className="flex-1 min-w-0">
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className="sm:flex sm:ml-4">
        <ButtonGroup
          className="mr-6"
          active="users"
          values={buttonGroupItems}
          onClick={handleButtonGroupItemClick}
        />
        {children}
        <Button
          labelText="New"
          as={Link}
          className="ml-6"
          to={`/merchants/${merchantId}/users/new`}
        />
      </div>
    </PageLayout.Header>
  );
};
