import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, Cell } from 'react-table';
import { Table } from '@fe/table';
import { Button } from '@fe/button';
import { TextInput } from '@fe/textinput';
import { PageLayout } from '@fe/layout';
import { useMounted } from '@fe/hooks';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Label } from '@fe/label';
import { Radio } from '@fe/radio';
import { AlertDialog } from '@fe/dialog';

import useFetch from '../hooks/useFetch';
import { useAppDispatch, useAppState } from '../app-context';
import { pageSizeOptions } from '../constants';

import {
  Page,
  MerchantUsersRequestParams,
  MerchantUserDetails,
  Role,
} from '../types';
import {
  findBrand,
  newPageNumberOnPageChange,
  newParamsOnMerchantSearch,
} from './utils';
import { MerchantUserHeader } from './MerchantUsersHeader';
import { MerchantUserDownloadButton } from '../components/MerchantUserDownloadButton';

/**
 * Page that manages merchants users.
 */
function MerchantUsers() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { merchantId } = useParams();
  const mounted = useMounted();
  const { executeRequest } = useFetch();
  const { brands } = useAppState();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [usersResult, setUsersResult] = useState<
    Page<MerchantUserDetails[]> | undefined
  >();

  const [filters, setFilters] = useState<
    Pick<MerchantUsersRequestParams, 'emailAddress' | 'role'>
  >({ emailAddress: '' });

  const [userToRevoke, setUserToRevoke] = useState<MerchantUserDetails | null>(
    null
  );

  const brand = useMemo(
    () => findBrand(brands, merchantId!),
    [merchantId, brands]
  );

  const queryParams = useMemo(() => {
    const query = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    return {
      ...query,
      page: query.page ? Number(query.page) : 0,
      pageSize: query.pageSize ? Number(query.pageSize) : 10,
    } as MerchantUsersRequestParams;
  }, [search]);

  const columns: Column<MerchantUserDetails>[] = useMemo(
    () => [
      {
        Header: 'Email Address',
        id: 'emailAddress',
        accessor: ({ emailAddress }) => (
          <div
            className="max-w-[100%] break-words text-ellipsis overflow-hidden"
            title={emailAddress}
          >
            {emailAddress}
          </div>
        ),
      },
      {
        Header: 'First Name',
        accessor: 'profile.firstName' as any,
        width: 65,
      },
      {
        Header: 'Last Name',
        accessor: 'profile.lastName' as any,
        width: 65,
      },
      {
        Header: 'Role',
        disableSortBy: true,
        accessor: ({ role }: { role: Role }) => (
          <>
            {role === 'BASIC'
              ? 'Basic'
              : role === 'MERCHANT_ADMIN'
              ? 'Merchant Admin'
              : 'Merchant Key Admin'}
          </>
        ),
        width: 70,
      },
      {
        Header: 'Actions',
        disableSortBy: true,
        maxWidth: 50,
        columnType: 'numeric', // Trick to align left
        Cell: ({ row }: Cell<MerchantUserDetails>) => (
          <>
            <Button
              appearance="subtle"
              labelText="Edit"
              icon="Edit"
              as={Link}
              to={`/merchants/${merchantId}/users/${row.original.userId}`}
            />
            <Button
              appearance="subtle"
              labelText="Delete"
              icon="Trash"
              onClick={() => setUserToRevoke(row.original)}
            />
          </>
        ),
      },
    ],
    [merchantId]
  );

  const disableClearAll = useMemo(
    () => !filters.emailAddress?.length && !filters.role?.length,
    [filters]
  );

  const renderTableEmptyState = useCallback(
    () => (
      <p className="text-sm text-center p-4">
        {!disableClearAll ? (
          <>
            No matching users found. Try adjusting your filters to to find
            users!
          </>
        ) : (
          <>There are no users yet. Start adding users!</>
        )}
      </p>
    ),
    [disableClearAll]
  );

  const getUsers = useCallback(async () => {
    mounted.current && setLoading(true);

    const { success, data } = await executeRequest<Page<MerchantUserDetails[]>>(
      `public/brands/${merchantId}/users` +
        qs.stringify(queryParams, { addQueryPrefix: true })
    );

    if (success) {
      mounted.current && setUsersResult(data);
    }

    mounted.current && setLoading(false);
  }, [executeRequest, queryParams, merchantId, mounted]);

  // Retrieve internal users from server
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  // Set filters from query parameters
  useEffect(() => {
    setFilters({
      emailAddress: queryParams.emailAddress,
      role: queryParams.role,
    });
  }, [queryParams]);

  const updateQueryParams = (query?: MerchantUsersRequestParams) => {
    const url = `/merchants/${merchantId}/users${
      !query
        ? ''
        : qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'repeat' })
    }`;
    navigate(url);
  };

  const handleEmailFilterChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number
  ) => setFilters({ ...filters, [name]: value });

  const handleRoleFilterChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, role: target.value as Role });

  const handleClearAll = () => {
    if (queryParams.emailAddress || queryParams.role) {
      updateQueryParams({
        ...queryParams,
        page: 0,
        emailAddress: undefined,
        role: undefined,
      });
      setFilters({ emailAddress: '', role: undefined });
    } else {
      setFilters({ emailAddress: '' });
    }
  };

  const handleRevokeDialogClose = async (confirmed: boolean) => {
    const { userId, emailAddress } = userToRevoke!;
    setUserToRevoke(null);

    if (confirmed) {
      const { success } = await executeRequest(
        `public/brands/${merchantId}/users/${userId}`,
        'DELETE'
      );

      if (mounted.current) {
        dispatch({
          type: 'add-alert',
          data: {
            id: Date.now(),
            appearance: success ? 'success' : 'error',
            title: success
              ? 'User was successfully revoked'
              : `Could not revoke user with email address ${emailAddress}`,
          },
        });
        getUsers();
      }
    }
  };

  if (!usersResult) {
    return <></>;
  }

  return (
    <PageLayout>
      <MerchantUserHeader>
        <MerchantUserDownloadButton
          disabled={!usersResult.content}
          makeRequest={() => {
            const { page, ...downloadParams } = {
              ...queryParams,
              pageSize: usersResult?.totalElements,
            };
            return executeRequest<Page<MerchantUserDetails[]>>(
              `public/brands/${merchantId}/users` +
                qs.stringify(downloadParams, { addQueryPrefix: true })
            );
          }}
        />
      </MerchantUserHeader>
      <PageLayout.Main>
        <PageLayout.AsideFilter
          onClearAll={handleClearAll}
          onSearch={() =>
            updateQueryParams(
              newParamsOnMerchantSearch(queryParams, filters, 0)
            )
          }
          disabledSearch={disableClearAll && !usersResult.totalElements}
          disabledClearAll={disableClearAll}
        >
          <div className="px-4 py-3 space-y-4">
            <TextInput
              label="Email"
              name="emailAddress"
              value={filters.emailAddress || ''}
              onChange={handleEmailFilterChange}
            />
            <div>
              <Label text="Role" />
              <Radio
                label="Basic"
                name="role_basic"
                value="BASIC"
                checked={filters.role === 'BASIC'}
                onChange={handleRoleFilterChange}
              />
              <Radio
                label="Merchant Admin"
                name="role_merchant"
                value="MERCHANT_ADMIN"
                checked={filters.role === 'MERCHANT_ADMIN'}
                onChange={handleRoleFilterChange}
              />
              <Radio
                label="Merchant Key Admin"
                name="role_merchant_key"
                value="MERCHANT_KEY_ADMIN"
                checked={filters.role === 'MERCHANT_KEY_ADMIN'}
                onChange={handleRoleFilterChange}
              />
            </div>
          </div>
        </PageLayout.AsideFilter>
        <PageLayout.Content>
          <Table
            loading={loading}
            columns={columns}
            data={usersResult.content}
            initialState={{
              //@ts-expect-error
              pageSize: queryParams.pageSize,
              pageIndex: queryParams.page,
              sortBy: Array.isArray(queryParams.sortBy)
                ? queryParams.sortBy.map(sort => ({
                    id: sort,
                    desc: queryParams.order === 'DESC',
                  }))
                : [
                    {
                      id: queryParams.sortBy,
                      desc: queryParams.order === 'DESC',
                    },
                  ],
            }}
            pagination={{
              pageSizeOptions,
              onChangePage: pageNumber =>
                updateQueryParams({
                  ...queryParams,
                  page: newPageNumberOnPageChange(pageNumber),
                }),
              onChangePageSize: recordsRequested =>
                updateQueryParams({
                  ...queryParams,
                  page: 0,
                  pageSize: recordsRequested,
                }),
              currentPage: queryParams.page + 1,
              pageSize: queryParams.pageSize,
              count: usersResult.totalElements,
              renderSection:
                usersResult.totalElements === 0
                  ? renderTableEmptyState
                  : undefined,
            }}
            onSort={(sortBy?: string, desc?: boolean) =>
              updateQueryParams({
                ...queryParams,
                sortBy: sortBy ? [sortBy] : [],
                order: desc ? 'DESC' : 'ASC',
              })
            }
          />
        </PageLayout.Content>
      </PageLayout.Main>
      <AlertDialog
        show={!!userToRevoke}
        type="danger"
        title="Revoke user access"
        description={
          <>
            <p>Are you sure you want to revoke access for user </p>
            <span className="font-bold">
              {userToRevoke?.emailAddress}
            </span> on <span className="font-bold">{brand?.name}</span>
          </>
        }
        confirmActionLabel="Revoke Access"
        onClose={handleRevokeDialogClose}
      />
    </PageLayout>
  );
}

export default withAuthenticationRequired(MerchantUsers);
