import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, Cell } from 'react-table';
import { Table } from '@fe/table';
import { AlertDialog } from '@fe/dialog';
import { Button } from '@fe/button';
import { TextInput } from '@fe/textinput';
import { PageLayout } from '@fe/layout';
import { useMounted } from '@fe/hooks';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { pageSizeOptions } from '../constants';
import useFetch from '../hooks/useFetch';
import { useAppDispatch } from '../app-context';

import { Page, InternalUsersRequestParams, UserDetails } from '../types';
import { newParamsOnAdminUserSearch } from './utils';

function AdminUsers() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const mounted = useMounted();
  const dispatch = useAppDispatch();

  const { executeRequest } = useFetch();
  const [usersResult, setUsersResult] = useState<
    Page<UserDetails[]> | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<
    Pick<InternalUsersRequestParams, 'emailAddress'>
  >({ emailAddress: '' });
  const [userToDelete, setUserToDelete] = useState<UserDetails | null>(null);

  const queryParams = useMemo(() => {
    const query = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    return {
      ...query,
      page: query.page ? Number(query.page) : 0,
      pageSize: query.pageSize ? Number(query.pageSize) : 10,
    } as InternalUsersRequestParams;
  }, [search]);

  // @ts-expect-error
  const columns: Column<UserDetails>[] = useMemo(
    () => [
      {
        Header: 'Email Address',
        accessor: 'emailAddress',
      },
      {
        Header: 'First Name',
        accessor: 'profile.firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'profile.lastName',
      },
      {
        Header: 'Actions',
        disableSortBy: true,
        maxWidth: 50,
        columnType: 'numeric', // Trick to align left
        Cell: ({ row }: Cell<UserDetails>) => (
          <>
            <Button
              appearance="subtle"
              labelText="Edit"
              icon="Edit"
              as={Link}
              to={`/admin/users/${row.original.userId}`}
            />
            <Button
              appearance="subtle"
              labelText="Delete"
              icon="Trash"
              onClick={() => setUserToDelete(row.original)}
            />
          </>
        ),
      },
    ],
    []
  );

  const getUsers = useCallback(async () => {
    mounted.current && setLoading(true);

    const { success, data } = await executeRequest<Page<UserDetails[]>>(
      'public/internal/users' +
        qs.stringify(queryParams, { addQueryPrefix: true })
    );

    if (success) {
      mounted.current && setUsersResult(data);
    }

    mounted.current && setLoading(false);
  }, [executeRequest, queryParams, mounted]);

  // Retrieve internal users from server
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  // Set filters from query parameters
  useEffect(() => {
    setFilters({
      emailAddress: queryParams.emailAddress,
    });
  }, [queryParams]);

  const updateQueryParams = (query?: InternalUsersRequestParams) => {
    const url = `/admin/users${
      !query
        ? ''
        : qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'repeat' })
    }`;

    navigate(url);
  };

  const handleFiltersChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number
  ) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleClearAll = () => {
    if (queryParams.emailAddress) {
      updateQueryParams({
        ...queryParams,
        page: 0,
        emailAddress: undefined,
      });
    } else {
      setFilters({ emailAddress: '' });
    }
  };

  const handleDeleteDialogClose = async (confirmed: boolean) => {
    const { userId, emailAddress } = userToDelete!;
    setUserToDelete(null);

    if (confirmed) {
      const { success } = await executeRequest(
        `public/internal/users/${userId}`,
        'DELETE'
      );

      if (mounted.current) {
        dispatch({
          type: 'add-alert',
          data: {
            id: Date.now(),
            appearance: success ? 'success' : 'error',
            title: success
              ? 'User was successfully deleted'
              : `Could not delete user with email address ${emailAddress}`,
          },
        });
        getUsers();
      }
    }
  };

  if (!usersResult) {
    return <></>;
  }

  return (
    <PageLayout>
      <PageLayout.Header>
        <div className="flex-1 min-w-0">
          <h1 className="text-lg leading-6 font-medium text-black">
            Admin Users
          </h1>
        </div>
        <div className="flex ml-4">
          <Button labelText="New" as={Link} to="/admin/users/new" />
        </div>
      </PageLayout.Header>
      <PageLayout.Main>
        <PageLayout.AsideFilter
          onClearAll={handleClearAll}
          onSearch={() =>
            updateQueryParams(
              newParamsOnAdminUserSearch(queryParams, filters, 0)
            )
          }
          disabledClearAll={!filters.emailAddress?.length}
        >
          <div className="px-4 py-3 space-y-4">
            <TextInput
              label="Email"
              name="emailAddress"
              value={filters.emailAddress || ''}
              onChange={handleFiltersChange}
            />
          </div>
        </PageLayout.AsideFilter>
        <PageLayout.Content>
          <Table
            loading={loading}
            columns={columns}
            data={usersResult.content}
            initialState={{
              //@ts-expect-error
              pageSize: queryParams.pageSize,
              pageIndex: queryParams.page,
              sortBy: Array.isArray(queryParams.sortBy)
                ? queryParams.sortBy.map(sort => ({
                    id: sort,
                    desc: queryParams.order === 'DESC',
                  }))
                : [
                    {
                      id: queryParams.sortBy,
                      desc: queryParams.order === 'DESC',
                    },
                  ],
            }}
            pagination={{
              pageSizeOptions,
              onChangePage: pageNumber =>
                updateQueryParams({ ...queryParams, page: pageNumber - 1 }),
              onChangePageSize: recordsRequested =>
                updateQueryParams({
                  ...queryParams,
                  page: 0,
                  pageSize: recordsRequested,
                }),
              currentPage: queryParams.page + 1,
              pageSize: queryParams.pageSize,
              count: usersResult.totalElements,
            }}
            onSort={(sortBy?: string, desc?: boolean) =>
              updateQueryParams({
                ...queryParams,
                sortBy: sortBy ? [sortBy] : [],
                order: desc ? 'DESC' : 'ASC',
              })
            }
          />
        </PageLayout.Content>
      </PageLayout.Main>
      <AlertDialog
        show={!!userToDelete}
        type="danger"
        title="Delete admin user"
        description={
          <>
            <p>Are you sure want to delete this admin user?</p>
            <p className="font-bold">{userToDelete?.emailAddress}</p>
          </>
        }
        confirmActionLabel="Yes, Delete"
        onClose={handleDeleteDialogClose}
      />
    </PageLayout>
  );
}

export default withAuthenticationRequired(AdminUsers);
