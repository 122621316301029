import { useEffect, useRef } from 'react';

export type PostRedirectProps = {
  url: string;
  values: Record<string, string | number | string[]>;
};

export default function PostRedirect({ url, values }: PostRedirectProps) {
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    formRef.current?.submit();
  }, [formRef]);

  return (
    <form ref={formRef} title="redirect form" action={url} method="POST">
      {Object.keys(values).map(key => (
        <input key={key} name={key} type="hidden" value={values[key]} />
      ))}
    </form>
  );
}
