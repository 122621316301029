import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { PortalLinks } from '../constants';

function Logout() {
  const { logout } = useAuth0();

  useEffect(() => {
    async function performLogout() {
      const options: RequestInit = {
        method: 'GET',
        credentials: 'include', // sends cookies cross-origin calls
      };

      // Logout from e-receipt partitions
      try {
        await Promise.all([
          fetch(`${PortalLinks.LATIN1}j_logout`, options),
          fetch(`${PortalLinks.UTF8}j_logout`, options),
        ]);
      } catch (error) {
        console.error('Failed to log out of e-receipts portal.', error);
      }

      // Logout from Auth0
      logout({
        returnTo: `${
          window.location.origin
        }/?type=success&message=${encodeURIComponent(
          "You've successfully logged out."
        )}`,
      });
    }

    performLogout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default Logout;
