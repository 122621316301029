import { useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { APIErrorResponse } from '../types';

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type RequestOptions = Omit<RequestInit, 'headers'>;

export interface HttpResponse<T> {
  success: boolean;
  data?: T;
  error?: APIErrorResponse;
}

const isSuccessResponse = (status: number) => status >= 200 && status < 400;

export default function useFetch() {
  const apiBaseUrl = useMemo(() => window.__env.apiBaseUrl, []);

  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();

  const executeRequest = useCallback(
    async <T,>(
      url: string,
      method: HttpMethods = 'GET',
      body?: unknown,
      headers: Record<string, string> = {},
      options: RequestOptions = {}
    ): Promise<HttpResponse<T>> => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          headers['Authorization'] = `Bearer ${token}`;
        }

        options.method = method;
        if (method !== 'GET' && body) {
          options.body = JSON.stringify(body);
        }

        const res = await fetch(`${apiBaseUrl}/v1/${url}`, {
          ...options,
          headers: new Headers({
            'Content-Type': 'application/json',
            ...headers,
          }),
        });

        if (res.status === 401) {
          loginWithRedirect();
        }

        let result;
        if (res.headers.get('content-type')?.includes('application/json')) {
          result = await res.json();
        }

        if (res.status === 201) {
          result = res.headers.get('location')?.split('/').pop();
        }

        const success = isSuccessResponse(res.status);

        return {
          success,
          ...(success
            ? { data: result as T }
            : { error: result as APIErrorResponse }),
        };
      } catch (error) {
        return {
          success: false,
          error: {
            message: 'Internal Error',
            description: 'An unknown error occurred.',
            status: 500,
            referenceId: '-1',
            validation: {},
          },
        };
      }
    },
    [isAuthenticated, getAccessTokenSilently, apiBaseUrl, loginWithRedirect]
  );

  return { executeRequest };
}
