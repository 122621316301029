import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AlertDialog } from '@fe/dialog';
import { Banner } from '@fe/banner';
import { Button } from '@fe/button';
import { Icon } from '@fe/icons';
import { TextInput } from '@fe/textinput';
import { PageLayout } from '@fe/layout';
import { useMounted } from '@fe/hooks';
import { Combobox, ComboboxItem } from '@fe/combobox';
import cn from 'classnames';
import { defaultTo, equals } from 'ramda';
import { useAppDispatch, useAppState } from '../app-context';

import useFetch from '../hooks/useFetch';

import {
  APIErrorResponse,
  UpsertMerchantUserRequest,
  MerchantUserDetails,
  Role,
  UserDetails,
  AssociatedBrand,
} from '../types';

import { UpsertMerchantUserHeader } from './UpsertMerchantUserHeader';
import { DisplayUserBrands } from '../components/DisplayUserBrands';

export const onCloseWithoutSaving = ({
  confirmed,
  merchantId,
  navigate,
  onCancel,
}: {
  confirmed: boolean;
  merchantId: string;
  navigate: NavigateFunction;
  onCancel: () => void;
}) => {
  if (confirmed) {
    navigate(`/merchants/${merchantId}/users`);
  } else {
    onCancel();
  }
};

const roleItems = (currentUser?: UserDetails): ComboboxItem<Role>[] => {
  const merchantKeyAdminItem = (currentUser?.type === 'INTERNAL' && {
    label: 'Merchant Key Admin',
    value: 'MERCHANT_KEY_ADMIN',
  }) as {
    label: string;
    value: Role;
  };

  const assignableRoles: ComboboxItem<Role | null | undefined>[] = [
    {
      label: 'Basic',
      value: 'BASIC',
    },
    {
      label: 'Merchant Admin',
      value: 'MERCHANT_ADMIN',
    },
    merchantKeyAdminItem,
  ];

  return assignableRoles.filter(element => !!element) as ComboboxItem<Role>[];
};

/**
 * Page to create / update merchant users.
 */
function UpsertMerchantUser() {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { merchantId, userId } = useParams();
  const dispatch = useAppDispatch();

  const { executeRequest } = useFetch();

  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(userId ? true : false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, boolean>>({});

  const [loadingUserError, setLoadingUserError] =
    useState<APIErrorResponse | null>(null);

  const [user, setUser] = useState<MerchantUserDetails | null>(null);
  const [data, setData] = useState<UpsertMerchantUserRequest>({
    emailAddress: '',
    role: 'MERCHANT_ADMIN',
  });

  const { currentUser } = useAppState();

  // Fetches user data for updating page
  useEffect(() => {
    async function getUser() {
      const { success, data, error } =
        await executeRequest<MerchantUserDetails>(
          `public/brands/${merchantId}/users/${userId}`
        );

      if (!success) {
        mounted.current && setLoadingUserError(error!);
      }

      if (data && mounted.current) {
        setData({
          emailAddress: data.emailAddress,
          profile: data.profile,
          role: data.role,
        });
        setUser(data);
      }

      mounted.current && setLoading(false);
    }

    if (userId) {
      getUser();
    } else {
      setUser(null);
      setData({
        emailAddress: '',
        role: 'MERCHANT_ADMIN',
      });
    }
  }, [userId, merchantId, mounted, executeRequest]);

  const manageFieldErrors = (field: string, error: boolean) => {
    if (error) {
      !fieldErrors[field] && setFieldErrors({ ...fieldErrors, [field]: error });
      return;
    }

    if (fieldErrors[field]) {
      const { [field]: _, ...rest } = fieldErrors;
      setFieldErrors(rest);
    }
  };

  // Handles email value changes for new users.
  const handleEmailChange = (
    _: ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number,
    error: boolean
  ) => {
    setData({
      ...data,
      emailAddress: value as string,
    });

    manageFieldErrors(name, error);
  };

  // Handles profile field changes
  const handleProfileChange = (
    _: ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number,
    error: boolean
  ) => {
    setData({
      ...data,
      profile: {
        ...data.profile!,
        [name]: value as string,
      },
    });

    manageFieldErrors(name, error);
  };

  const handleRoleChange = (item?: ComboboxItem<Role> | null) => {
    manageFieldErrors('role', !item);
    setData({ ...data, role: item?.value! });
  };

  const isFormValid = useMemo(
    () => !Object.keys(fieldErrors).length,
    [fieldErrors]
  );

  const upsertUser = async () => {
    setBusy(true);

    const httpRequest = userId
      ? executeRequest(
          `public/brands/${merchantId}/users/${userId}`,
          'PUT',
          data
        )
      : executeRequest<string>(
          `public/brands/${merchantId}/users`,
          'POST',
          data
        );

    const result = await httpRequest;

    if (mounted.current) {
      setBusy(false);

      if (result.success) {
        // Reset any previous error
        error && setError(null);

        dispatch({
          type: 'add-alert',
          data: {
            id: Date.now(),
            appearance: 'success',
            title: `User was successfully ${userId ? 'updated' : 'added'}!`,
          },
        });
      } else {
        setError(result.error!.description);
      }

      if (result.data) {
        navigate(`/merchants/${merchantId}/users/${result.data}`, {
          replace: true,
        });
        return;
      }

      // Updates user data to track data changes.
      userId && setUser({ ...user!, profile: data.profile!, role: data.role });
    }
  };

  const handleResendEmail = async () => {
    setBusy(true);

    const { success, error } = await executeRequest(
      `public/users/${userId}/_reinvite`,
      'POST'
    );

    if (mounted.current) {
      setBusy(false);

      dispatch({
        type: 'add-alert',
        data: {
          id: Date.now(),
          appearance: success ? 'success' : 'error',
          title: success ? 'Sent verification email!' : error!.description,
        },
      });
    }
  };

  const handleClickSave = () => {
    if (!userId) {
      setShowAddConfirmation(true);
    } else {
      upsertUser();
    }
  };

  const handleClickClose = () => {
    if (
      userId &&
      (!equals(user?.profile, data.profile) || !equals(user?.role, data.role))
    ) {
      setShowDiscardConfirmation(true);
      return;
    }

    navigate(`/merchants/${merchantId}/users`);
  };

  const handleDialogClose = (confirmed: boolean) => {
    setShowAddConfirmation(false);

    if (confirmed) {
      upsertUser();
    }
  };

  const renderErrorMessage = (errorMessage: string) => (
    <>
      {errorMessage.endsWith('.') ? errorMessage : `${errorMessage}.`}
      <>
        &nbsp;Please contact your administrator or
        <a
          className="text-green-pakistan-700 font-semibold"
          href="mailto:support@flexengage.com"
        >
          &nbsp;Support
        </a>
      </>
    </>
  );

  if (loading) {
    return null;
  }

  if (!loading && loadingUserError) {
    return (
      <AlertDialog
        show
        type="danger"
        title={loadingUserError.message}
        description={loadingUserError.description}
        confirmActionLabel="Go back to users"
        showCancelAction={false}
        onClose={() => navigate(`/merchants/${merchantId}/users`)}
        shouldCloseOnOverlayClick={false}
      />
    );
  }

  const brands = defaultTo([] as AssociatedBrand[], user?.brands);

  return (
    <PageLayout>
      <UpsertMerchantUserHeader user={user} />
      <main className="px-0 xl:px-8">
        <div className="flex justify-end mb-4">
          <div className="space-x-1">
            {(user?.status === 'CONFIRMED' || !userId) && (
              <Button
                appearance="subtle"
                labelText="Cancel"
                onClick={handleClickClose}
                disabled={busy}
              />
            )}
            <Button
              labelText="Save"
              onClick={handleClickSave}
              disabled={busy || !isFormValid || user?.status === 'UNCONFIRMED'}
            />
          </div>
        </div>
        <div className="relative bg-white border border-grey-davys-50 rounded-sm px-8 pt-6 pb-8 min-h-[800px]">
          {error && (
            <div className="mb-6">
              <Banner
                type="banner"
                appearance="error"
                message={renderErrorMessage(error)}
                onClose={() => setError(null)}
              />
            </div>
          )}
          <div
            className={`grid grid-cols-1 gap-y-6 lg:grid-cols-[350px_350px_Auto] lg:gap-x-8`}
          >
            <TextInput
              label="Email"
              name="emailAddress"
              placeholder="user@company.com"
              value={data.emailAddress}
              required
              autoFocus={!userId}
              type="email"
              onChange={handleEmailChange}
              disabled={userId !== undefined}
            />
            {user ? (
              <>
                <span className="flex items-start gap-1 text-sm">
                  {user.status === 'CONFIRMED' ? (
                    <>
                      <Icon name="BadgeCheck" className="text-green-600" />
                      <span>Verified user</span>
                    </>
                  ) : (
                    <>
                      <Icon name="Ban" className="text-red-600" />
                      {user.status === 'UNCONFIRMED' && (
                        <>
                          <span>Not verified.</span>
                          <span
                            className={cn(
                              'pl-1 text-orange-500 cursor-pointer',
                              {
                                'pointer-events-none': busy,
                              }
                            )}
                            onClick={handleResendEmail}
                          >
                            Resend verification email?
                          </span>
                        </>
                      )}
                    </>
                  )}
                </span>
                <div
                  className={`xl:self-start relative col-start-1 xl:col-start-3 xl;min-w-[300px] xl:h-auto`}
                >
                  <DisplayUserBrands
                    className="xl:max-h-[700px] overflow-y-scroll xl:absolute"
                    brands={brands}
                  />
                </div>
              </>
            ) : (
              <div></div>
            )}
            {user?.status === 'CONFIRMED' && (
              <>
                <TextInput
                  rootClassName="col-start-1 col-span-1"
                  label="First Name"
                  name="firstName"
                  required
                  autoFocus={!!userId}
                  value={data.profile!.firstName || ''}
                  onChange={handleProfileChange}
                />
                <TextInput
                  rootClassName="col-start-1 col-span-1"
                  label="Last Name"
                  name="lastName"
                  required
                  value={data.profile!.lastName || ''}
                  onChange={handleProfileChange}
                />
              </>
            )}
            <Combobox
              rootClassName="col-start-1 col-span-1"
              label="Role"
              items={roleItems(currentUser)}
              error={fieldErrors.role ? 'This field is required' : undefined}
              selectedItem={
                roleItems(currentUser).find(m => m.value === data.role) || null
              }
              onClickItem={handleRoleChange}
            />
          </div>
        </div>
      </main>
      <AlertDialog
        show={showAddConfirmation}
        icon="UserAdd"
        title="Add merchant user"
        description={
          <>
            <p>Are you sure want to add this merchant user?</p>
            <p className="font-bold">{data.emailAddress}</p>
          </>
        }
        confirmActionLabel="Yes, Add"
        showCancelAction
        onClose={handleDialogClose}
        shouldCloseOnOverlayClick
      />
      <AlertDialog
        type="danger"
        show={showDiscardConfirmation}
        title="Pending Changes"
        description="You have unsaved changes. Are you sure you want to cancel?"
        confirmActionLabel="Close without Saving"
        showCancelAction
        onClose={confirmed => {
          if (confirmed) {
            navigate(`/merchants/${merchantId}/users`);
          } else {
            setShowDiscardConfirmation(false);
          }
        }}
        shouldCloseOnOverlayClick
      />
    </PageLayout>
  );
}

export default withAuthenticationRequired(UpsertMerchantUser);
