import cn from 'classnames';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Banner } from '@fe/banner';
import { Button } from '@fe/button';
import { Icon } from '@fe/icons';
import { Spinner } from '@fe/spinner';
import { TextInput } from '@fe/textinput';
import { useMounted } from '@fe/hooks';
import { TwoPanelLayout } from '@fe/layout';
import createPasswordPolicy from 'password-sheriff';
import { useAuth0 } from '@auth0/auth0-react';

import useFetch from '../hooks/useFetch';

import { APIErrorResponse, VerifyAndRegisterRequest } from '../types';

type Rule = { code: string };

interface PasswordValidationRules {
  lengthAtLeast: boolean;
  containsAtLeast: {
    lowerCase: boolean;
    upperCase: boolean;
    numbers: boolean;
    specialCharacters: boolean;
    verified: boolean;
  };
  verified: boolean;
}

export default function Registration() {
  const { loginWithRedirect } = useAuth0();
  const mounted = useMounted();
  const { verificationToken } = useParams();
  const [searchParams] = useSearchParams();
  const { executeRequest } = useFetch();

  const passwordPolicy = useMemo(() => createPasswordPolicy('good'), []);

  const [isValidToken, setIsValidToken] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<APIErrorResponse | null>(null);

  const [registrationForm, setRegistrationForm] =
    useState<VerifyAndRegisterRequest>({
      emailAddress: searchParams.get('email') || '',
      password: '',
      verificationToken: verificationToken || '',
      profile: {
        firstName: '',
        lastName: '',
      },
    });

  const [verifiedPasswordValidationRules, setVerifiedPasswordValidationRules] =
    useState<PasswordValidationRules>({
      verified: false,
      lengthAtLeast: false,
      containsAtLeast: {
        lowerCase: false,
        numbers: false,
        specialCharacters: false,
        upperCase: false,
        verified: false,
      },
    });

  useEffect(() => {
    async function verifyToken() {
      const { success } = await executeRequest(
        'public/users/me/_verify',
        'POST',
        { emailAddress: registrationForm.emailAddress, verificationToken }
      );

      if (success) {
        mounted.current && setIsValidToken(true);
      } else {
        loginWithRedirect({
          ui_banner_type: 'error',
          ui_banner_message:
            'Your verification link has expired. Please contact your administrator or <a class="fe-btn-link" href="mailto:support@flexengage.com">Support</a>',
        });
      }
    }

    verifyToken();
  }, [
    executeRequest,
    loginWithRedirect,
    mounted,
    registrationForm.emailAddress,
    verificationToken,
  ]);

  const handleProfileChange = (
    _: ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number
  ) => {
    setRegistrationForm({
      ...registrationForm,
      profile: { ...registrationForm.profile, [name]: value as string },
    });
  };

  const handlePasswordChange = (
    _: ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number
  ) => {
    setRegistrationForm({ ...registrationForm, password: value as string });
    const result = passwordPolicy.missing(value);

    const containsAtLeastRules = result.rules.find(
      (rule: { code: string }) => rule.code === 'containsAtLeast'
    );

    setVerifiedPasswordValidationRules({
      verified: result.verified,
      lengthAtLeast:
        result.rules.find((rule: Rule) => rule.code === 'lengthAtLeast')
          ?.verified || false,
      containsAtLeast: {
        lowerCase:
          containsAtLeastRules.items.find(
            (rule: Rule) => rule.code === 'lowerCase'
          )?.verified || false,
        numbers:
          containsAtLeastRules.items.find(
            (rule: Rule) => rule.code === 'numbers'
          )?.verified || false,
        specialCharacters:
          containsAtLeastRules.items.find(
            (rule: Rule) => rule.code === 'specialCharacters'
          )?.verified || false,
        upperCase:
          containsAtLeastRules.items.find(
            (rule: Rule) => rule.code === 'upperCase'
          )?.verified || false,
        verified: containsAtLeastRules.verified,
      },
    });
  };

  const isFormValid = () => {
    // Validate profile values
    if (
      !registrationForm.profile.firstName.trim().length ||
      !registrationForm.profile.lastName.trim().length
    ) {
      return false;
    }

    // Check password requirements
    if (!verifiedPasswordValidationRules.verified) {
      return false;
    }

    // Password confirmation
    if (registrationForm.password !== verifyPassword) {
      return false;
    }

    return true;
  };

  const handleFormSubmission = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!isFormValid()) {
      return;
    }

    if (mounted.current) {
      setError(null);
      setSaving(true);
    }

    const { success, error } = await executeRequest(
      'public/users/me/_register',
      'POST',
      registrationForm
    );

    if (success) {
      loginWithRedirect({
        ui_banner_type: 'success',
        ui_banner_message:
          "You're registered! Please bookmark this page for future logins.",
      });
    } else {
      if (mounted.current) {
        setSaving(false);
        error && setError(error);
      }
    }
  };

  if (!isValidToken) {
    return <Spinner className="h-screen" size="md" />;
  }

  return (
    <TwoPanelLayout>
      <TwoPanelLayout.LeftPanel>
        <div className="flex justify-center w-auto mx-auto h-12">
          <img
            className="h-10"
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="logo"
          />
        </div>
        {error && (
          <div className="mt-4">
            <Banner
              appearance="error"
              message={
                <>
                  <span className="block mb-0.5">{error.description}</span>
                  <span className="block">
                    Please contact
                    <a
                      className="text-green-pakistan-700 hover:text-green-pakistan-900"
                      href="mailto:support@flexengage.com"
                    >
                      &nbsp;Support&nbsp;
                    </a>
                    for assistance
                  </span>
                  <span>Reference ID:&nbsp; {error.referenceId}</span>
                </>
              }
            />
          </div>
        )}
        <form onSubmit={handleFormSubmission} className="mt-4 space-y-6">
          <TextInput
            label="Login Email"
            name="email"
            value={registrationForm.emailAddress}
            disabled
          />
          <TextInput
            label="First Name"
            name="firstName"
            required
            value={registrationForm.profile.firstName}
            onChange={handleProfileChange}
            autoFocus
          />
          <TextInput
            label="Last Name"
            name="lastName"
            required
            value={registrationForm.profile.lastName}
            onChange={handleProfileChange}
          />
          <div>
            <div className="flex items-end">
              <TextInput
                type="password"
                rootClassName="w-full"
                className="pr-10"
                label="New Password"
                name="password"
                required
                value={registrationForm.password}
                onChange={handlePasswordChange}
              />
              {verifiedPasswordValidationRules.verified && (
                <Icon
                  name="CircleCheck"
                  className="text-green-500 -ml-7 mb-2"
                />
              )}
            </div>
            <div>
              <p className="mt-1 text-sm text-grey-davys-500">Password must:</p>
              <div className="flex mt-2 text-sm text-grey-davys-200">
                <Icon
                  name={
                    verifiedPasswordValidationRules.lengthAtLeast
                      ? 'CircleCheck'
                      : 'InformationCircle'
                  }
                  className={cn('mr-1', {
                    'text-green-500':
                      verifiedPasswordValidationRules.lengthAtLeast,
                  })}
                />
                <p>
                  Be at least
                  <span
                    className={cn({
                      'text-green-500':
                        verifiedPasswordValidationRules.lengthAtLeast,
                    })}
                  >
                    &nbsp;8 characters long.
                  </span>
                </p>
              </div>
              <div className="flex mt-2 text-sm text-grey-davys-200">
                <Icon
                  name={
                    verifiedPasswordValidationRules.containsAtLeast.verified
                      ? 'CircleCheck'
                      : 'InformationCircle'
                  }
                  className={cn('mr-1', {
                    'text-green-500':
                      verifiedPasswordValidationRules.containsAtLeast.verified,
                  })}
                />
                <div className="flex-1">
                  <p>
                    Include at least 3 of the following 4 types of characters:
                    <span
                      className={cn({
                        'text-green-500':
                          verifiedPasswordValidationRules.containsAtLeast
                            .lowerCase,
                      })}
                    >
                      &nbsp;a lower-case letter
                    </span>
                    ,
                    <span
                      className={cn({
                        'text-green-500':
                          verifiedPasswordValidationRules.containsAtLeast
                            .upperCase,
                      })}
                    >
                      &nbsp;an upper-case letter
                    </span>
                    ,
                    <span
                      className={cn({
                        'text-green-500':
                          verifiedPasswordValidationRules.containsAtLeast
                            .numbers,
                      })}
                    >
                      &nbsp;a number
                    </span>
                    ,
                    <span
                      className={cn({
                        'text-green-500':
                          verifiedPasswordValidationRules.containsAtLeast
                            .specialCharacters,
                      })}
                    >
                      &nbsp;a special character (i.e: !@#$).
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-end">
            <TextInput
              type="password"
              rootClassName="w-full"
              className="pr-10"
              label="Retype Password"
              name="confirmPassword"
              required
              onChange={(_, __, value) => setVerifyPassword(value as string)}
              value={verifyPassword || ''}
            />
            {verifyPassword && verifyPassword === registrationForm.password && (
              <Icon name="CircleCheck" className="text-green-500 -ml-7 mb-2" />
            )}
          </div>
          <Button
            type="submit"
            labelText="Register"
            className="w-full"
            size="lg"
            disabled={!isFormValid() || saving}
          />
        </form>
      </TwoPanelLayout.LeftPanel>
      <TwoPanelLayout.RightPanel>
        <h1 className="text-white m-0 font-normal text-6xl">
          Let's get started
        </h1>
      </TwoPanelLayout.RightPanel>
    </TwoPanelLayout>
  );
}
