import { Button } from '@fe/button';
import { defaultTo } from 'ramda';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HttpResponse } from '../hooks/useFetch';
import { MerchantUserDetails, Page } from '../types';

export const getUserCSV = (data: Page<MerchantUserDetails[]>) => {
  const header = 'email,last name,first name,role,status,user id,created date';
  const rows = defaultTo(
    [],
    data?.content.map(
      ({
        emailAddress,
        profile: { lastName, firstName },
        role,
        status,
        userId,
        createdAt,
      }) =>
        [
          emailAddress,
          lastName,
          firstName,
          role,
          status,
          userId,
          createdAt,
        ].join(',')
    )
  );
  const csv = [header, ...rows].join('\n');
  return csv;
};

export const makeDownloader = (
  csv: string,
  merchantId: string,
  totalElements: number
) => {
  var blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;',
  });

  var url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute(
    'download',
    `users_${merchantId}_total_${totalElements}.csv`
  );

  link.href = url;
  return link;
};

export const download = (link: HTMLAnchorElement) => {
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const MerchantUserDownloadButton = ({
  disabled,
  makeRequest,
}: {
  disabled?: boolean;
  makeRequest: () => Promise<HttpResponse<Page<MerchantUserDetails[]>>>;
}) => {
  const { merchantId } = useParams();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      labelText="Download"
      title="Download the user date currently displayed on the screen."
      appearance="accent"
      onClick={async () => {
        try {
          setLoading(true);
          const { data, success } = await makeRequest();
          if (success && data) {
            download(
              makeDownloader(
                getUserCSV(data!),
                defaultTo('', merchantId),
                defaultTo(0, data?.totalElements)
              )
            );
          }
        } catch {}
        setLoading(false);
      }}
      disabled={disabled || loading}
    />
  );
};
