import { useMemo, useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { Combobox, ComboboxItem } from '@fe/combobox';

import { getUrlFromPartition } from '../constants';
import PostRedirect, { PostRedirectProps } from '../components/PostRedirect';
import { useAppState } from '../app-context';

function AccountPicker() {
  const { getAccessTokenSilently } = useAuth0();
  const { brands } = useAppState();

  const items: ComboboxItem<string>[] = useMemo(
    () =>
      brands.map(({ id, name }) => ({
        label: `${name} (${id})`,
        value: id,
      })),
    [brands]
  );

  const [postRedirectValues, setPostRedirectValues] =
    useState<PostRedirectProps | null>(null);

  const handleItemClick = async (item?: ComboboxItem<string> | null) => {
    if (!item) {
      return;
    }

    const brand = brands.find(b => b.id === item.value);
    if (!brand) {
      return;
    }

    const access_token = await getAccessTokenSilently();
    const baseUrl = getUrlFromPartition(brand.brandPartition);

    setPostRedirectValues({
      url: `${baseUrl}authenticate`,
      values: { access_token, redirectURI: `/receipt/`, brandId: brand.id },
    });
  };

  if (postRedirectValues) {
    return <PostRedirect {...postRedirectValues} />;
  }

  return (
    <div className="flex flex-col sm:flex-row justify-center text-black font-medium text-xl h-screen items-center -mt-6 py-6">
      <div className="flex flex-col items-center justify-center bg-white w-1/2 h-64 my-4 p-4 shadow-sm mx-4 sm:mx-10 md:mx-16">
        <Combobox
          rootClassName="w-1/2"
          label="Choose account"
          items={items}
          onClickItem={handleItemClick}
        />
      </div>
    </div>
  );
}

export default withAuthenticationRequired(AccountPicker);
