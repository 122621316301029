import {
  Brand,
  InternalUsersRequestParams,
  MerchantUsersRequestParams,
} from '../types';

export const setHref = (href: string) => {
  window.location.assign(href);
};

export const newPageNumberOnPageChange = (page: number) => page - 1;

export const newParamsOnMerchantSearch = (
  queryParams: MerchantUsersRequestParams,
  filters: Pick<MerchantUsersRequestParams, 'emailAddress' | 'role'>,
  page: number
) => ({ ...queryParams, ...filters, page });

export const newParamsOnAdminUserSearch = (
  queryParams: InternalUsersRequestParams,
  filters: Pick<InternalUsersRequestParams, 'emailAddress'>,
  page: number
) => ({ ...queryParams, ...filters, page });

export const findBrand = (brands: Brand[], merchantId: string) =>
  brands.find(b => b.id === merchantId);
