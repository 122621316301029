import { useEffect, useState } from 'react';
import { Spinner } from '@fe/spinner';
import { useMounted } from '@fe/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import { datadogRum } from '@datadog/browser-rum';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Outlet,
} from 'react-router-dom';
import { Notification } from '@fe/notification';

import useFetch from './hooks/useFetch';
import { getUrlFromPartition } from './constants';
import PostRedirect, { PostRedirectProps } from './components/PostRedirect';

import { Brand, UserDetails } from './types';
import { useAppDispatch, useAppState } from './app-context';

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { alerts } = useAppState();
  const dispatch = useAppDispatch();

  const mounted = useMounted();
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    error,
    getAccessTokenSilently,
  } = useAuth0();
  const { executeRequest } = useFetch();
  const [searchParams] = useSearchParams();
  const [postRedirectValues, setPostRedirectValues] =
    useState<PostRedirectProps | null>(null);

  useEffect(() => {
    const posthogConfig = window.__env.posthogConfig;
    const datadogConfig = window.__env.datadogConfig;
    if (posthogConfig) {
      posthog.init(posthogConfig.apiKey, { api_host: posthogConfig.apiHost });
    }
    if (datadogConfig) {
      datadogRum.init(datadogConfig);
    }
  }, []);

  useEffect(() => {
    async function getCurrentUser() {
      const { success, data } = await executeRequest<UserDetails>(
        'public/users/me'
      );
      if (!success || !data) {
        // TODO: DO we show error?
        return;
      }
      datadogRum.setUser({
        id: data.userId,
        isAdmin: data.type === 'INTERNAL',
        userType: data.type,
      });

      posthog.identify(data.userId, {
        isAdmin: data.type === 'INTERNAL',
        email: data.emailAddress,
      });

      mounted.current && dispatch({ type: 'set-current-user', data });

      if (data.type === 'INTERNAL') {
        const { data } = await executeRequest<{ brandSummaries: Brand[] }>(
          'public/brands?pageSize=1000'
        );
        mounted.current &&
          data &&
          dispatch({ type: 'set-brands', data: data.brandSummaries });
      }

      // Don't redirect on other paths than these.
      if (pathname !== '/' && pathname !== '/account-picker') {
        return;
      }

      if (data.type === 'INTERNAL') {
        pathname === '/' && navigate('/account-picker', { replace: true });
      } else {
        const access_token = await getAccessTokenSilently();
        const url = getUrlFromPartition(data.brandRoles[0].partition);
        setPostRedirectValues({
          url: `${url}authenticate`,
          values: { access_token, redirectURI: '/receipt/' },
        });
      }
    }

    if (isAuthenticated) {
      getCurrentUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  function createRedirectMessage(type?: string, message?: string) {
    const ui_banner_type = getMessageType() || type;
    const ui_banner_message = searchParams.get('message') || message;

    if (!ui_banner_message) return;

    return {
      ui_banner_type,
      ui_banner_message,
    };
  }

  if (isLoading) {
    return <Spinner className="min-h-screen" size="md" />;
  }

  if (error) {
    const options = createRedirectMessage('error', error.message);
    loginWithRedirect(options);
  }

  if (!isAuthenticated && pathname === '/') {
    const options = createRedirectMessage();
    loginWithRedirect(options);
  }

  if (postRedirectValues) {
    return <PostRedirect {...postRedirectValues} />;
  }

  return (
    <>
      <Outlet />
      {alerts.map(alert => (
        <Notification
          key={alert.id}
          show={true}
          appearance={alert.appearance}
          title={alert.title}
          message={alert.message}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() =>
            dispatch({ type: 'remove-alert', data: { id: alert.id } })
          }
        />
      ))}
    </>
  );

  function getMessageType() {
    const successParam = searchParams.get('success');
    if (successParam) {
      return successParam === 'true' ? 'success' : 'error';
    }
    return searchParams.get('type');
  }
}
