import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import PostRedirect, { PostRedirectProps } from './components/PostRedirect';

import { getUrlFromPartition } from './constants';
import { AlertDialog } from '@fe/dialog';

function Redirect() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [postRedirectValues, setPostRedirectValues] =
    useState<PostRedirectProps | null>(null);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function setRedirectValues() {
      const brandId = searchParams.get('brandId');
      const partitionId = searchParams.get('partitionId');
      if (!brandId || !partitionId) {
        setError('There was an internal error');
        return;
      }

      const url = getUrlFromPartition(partitionId);

      const access_token = await getAccessTokenSilently();

      setPostRedirectValues({
        url: `${url}authenticate`,
        values: {
          brandId,
          access_token,
          redirectURI: searchParams.get('redirectURI') || '/',
        },
      });
    }

    setRedirectValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <AlertDialog
        show
        title="Oop!!"
        description={
          <p>
            {error}. Please contact your administrator or
            <a
              className="text-orange-500 hover:text-orange-700 transition duration-150 ease-in-out outline-none"
              href="mailto:support@flexengage.com"
            >
              &nbsp;Support
            </a>
          </p>
        }
        type="danger"
        confirmActionLabel="Go to Home Page"
        showCancelAction={false}
        shouldCloseOnOverlayClick={false}
        onClose={() => navigate('/', { replace: true })}
      />
    );
  }

  return postRedirectValues && <PostRedirect {...postRedirectValues} />;
}

export default withAuthenticationRequired(Redirect);
