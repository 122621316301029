import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AlertDialog } from '@fe/dialog';
import { Banner } from '@fe/banner';
import { Button } from '@fe/button';
import { Icon } from '@fe/icons';
import { TextInput } from '@fe/textinput';
import { PageLayout } from '@fe/layout';
import { Breadcrumb } from '@fe/breadcrumb';
import { useMounted } from '@fe/hooks';
import cn from 'classnames';

import useFetch from '../hooks/useFetch';

import {
  UserDetails,
  UpsertInternalUserRequest,
  APIErrorResponse,
} from '../types';
import { useAppDispatch } from '../app-context';

export const navigateFromAdminUserBreadcrumb = (
  navigate: NavigateFunction,
  length: number
) => {
  if (length > 2) {
    navigate(-1);
  } else {
    navigate('/admin/users', { replace: true });
  }
};

/**
 * Page to create / update internal admin users.
 */
function UpsertInternalUser() {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { userId } = useParams();
  const dispatch = useAppDispatch();

  const { executeRequest } = useFetch();

  const [error, setError] = useState<string | null>(null);

  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(userId ? true : false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, boolean>>({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [loadingUserError, setLoadingUserError] =
    useState<APIErrorResponse | null>(null);

  const [user, setUser] = useState<UserDetails | null>(null);
  const [data, setData] = useState<UpsertInternalUserRequest>({
    emailAddress: '',
  });

  const breadcrumbItems = useMemo(
    () => [
      { label: 'Admin Users', value: 'admin_users' },
      user
        ? {
            label: user.emailAddress,
            value: user.userId,
          }
        : {
            label: 'Create User',
            value: 'create-user',
          },
    ],
    [user]
  );

  // Fetches user data for updating page
  useEffect(() => {
    async function getUser() {
      const { success, data, error } = await executeRequest<UserDetails>(
        `public/internal/users/${userId}`
      );

      if (!success) {
        setLoadingUserError(error!);
      }

      if (data && mounted.current) {
        setData({
          emailAddress: data.emailAddress,
          profile: data.profile,
        });
        setUser(data);
      }

      mounted.current && setLoading(false);
    }

    if (userId) {
      getUser();
    } else {
      setUser(null);
      setData({
        emailAddress: '',
      });
    }
  }, [userId, mounted, executeRequest]);

  const manageFieldErrors = (field: string, error: boolean) => {
    if (error) {
      !fieldErrors[field] && setFieldErrors({ ...fieldErrors, [field]: error });
    } else {
      if (fieldErrors[field]) {
        const { [field]: _, ...rest } = fieldErrors;
        setFieldErrors(rest);
      }
    }
  };

  // Handles email value changes for new users.
  const handleEmailChange = (
    _: ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number,
    error: boolean
  ) => {
    setData({
      ...data,
      emailAddress: value as string,
    });

    manageFieldErrors(name, error);
  };

  // Handles profile field changes
  const handleProfileChange = (
    _: ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number,
    error: boolean
  ) => {
    setData({
      ...data,
      profile: {
        ...data.profile!,
        [name]: value as string,
      },
    });

    manageFieldErrors(name, error);
  };

  const isFormValid = useMemo(
    () => !Object.keys(fieldErrors).length,
    [fieldErrors]
  );

  const upsertUser = async () => {
    setBusy(true);

    const httpRequest = userId
      ? executeRequest<undefined>(
          `public/internal/users/${userId}`,
          'PUT',
          data
        )
      : executeRequest<string>('public/internal/users', 'POST', data);

    const result = await httpRequest;

    if (mounted.current) {
      result.success
        ? dispatch({
            type: 'add-alert',
            data: {
              id: Date.now(),
              appearance: 'success',
              title: `User was successfully ${userId ? 'updated' : 'added'}!`,
            },
          })
        : setError(result.error!.description);

      setBusy(false);

      result.data && navigate(`/admin/users/${result.data}`, { replace: true });
    }
  };

  const handleResendEmail = async () => {
    setBusy(true);

    const { success, error } = await executeRequest(
      `public/users/${userId}/_reinvite`,
      'POST'
    );

    if (mounted.current) {
      setBusy(false);

      dispatch({
        type: 'add-alert',
        data: {
          id: Date.now(),
          appearance: success ? 'success' : 'error',
          title: success ? 'Sent verification email!' : error!.description,
        },
      });
    }
  };

  const handleClickSave = () => {
    if (!userId) {
      setShowConfirmation(true);
    } else {
      upsertUser();
    }
  };

  const handleDialogClose = (confirmed: boolean) => {
    setShowConfirmation(false);

    if (confirmed) {
      upsertUser();
    }
  };

  const handleBreadcrumbItemClick = () => {
    navigateFromAdminUserBreadcrumb(navigate, window.history.length);
  };

  if (loading) {
    return null;
  }

  if (!loading && loadingUserError) {
    return (
      <AlertDialog
        show
        type="danger"
        title={loadingUserError.message}
        description={loadingUserError.description}
        confirmActionLabel="Go back to users"
        showCancelAction={false}
        onClose={() => navigate('/admin/users')}
        shouldCloseOnOverlayClick={false}
      />
    );
  }

  return (
    <PageLayout>
      <PageLayout.Header>
        <Breadcrumb
          items={breadcrumbItems}
          onItemClick={handleBreadcrumbItemClick}
        />
      </PageLayout.Header>
      <main className="px-0 lg:px-8">
        <div className="flex justify-end mb-4">
          <Button
            labelText="Save"
            onClick={handleClickSave}
            disabled={busy || !isFormValid || user?.status === 'UNCONFIRMED'}
          />
        </div>
        <div className="relative bg-white border border-grey-davys-50 rounded-sm px-8 pt-6 pb-8">
          {error && (
            <div className="mb-6">
              <Banner
                type="banner"
                appearance="error"
                message={error}
                onClose={() => setError(null)}
              />
            </div>
          )}
          <div className="grid grid-cols-1 space-y-6 sm:grid-cols-2 sm:gap-x-4 sm:items-center">
            <TextInput
              label="Email"
              name="emailAddress"
              placeholder="user@company.com"
              value={data.emailAddress}
              required
              autoFocus={!userId}
              type="email"
              onChange={handleEmailChange}
              disabled={userId !== undefined}
            />
            {user && (
              <>
                <div className="flex items-center mt-6 text-sm">
                  <Icon
                    name={user.status === 'CONFIRMED' ? 'BadgeCheck' : 'Ban'}
                    className={cn('mr-0.5', {
                      'text-red-600': user.status === 'UNCONFIRMED',
                      'text-green-600': user.status === 'CONFIRMED',
                    })}
                  />
                  <p>
                    {user.status === 'UNCONFIRMED'
                      ? 'Not verified.'
                      : 'Verified user'}
                  </p>
                  {user.status === 'UNCONFIRMED' && (
                    <p
                      className={cn('text-orange-500 cursor-pointer', {
                        'pointer-events-none': busy,
                      })}
                      onClick={handleResendEmail}
                    >
                      &nbsp;Resend verification email?
                    </p>
                  )}
                </div>
                {user.status === 'CONFIRMED' && (
                  <>
                    <TextInput
                      rootClassName="col-start-1 col-span-1"
                      label="First Name"
                      name="firstName"
                      required
                      autoFocus={!!userId}
                      value={data.profile!.firstName || ''}
                      onChange={handleProfileChange}
                    />
                    <TextInput
                      rootClassName="col-start-1 col-span-1"
                      label="Last Name"
                      name="lastName"
                      required
                      value={data.profile!.lastName || ''}
                      onChange={handleProfileChange}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </main>
      <AlertDialog
        show={showConfirmation}
        icon="UserAdd"
        title="Add admin user"
        description={
          <>
            <p>Are you sure want to add this admin user?</p>
            <p className="font-bold">{data.emailAddress}</p>
          </>
        }
        confirmActionLabel="Yes, Add"
        showCancelAction
        onClose={handleDialogClose}
        shouldCloseOnOverlayClick
      />
    </PageLayout>
  );
}

export default withAuthenticationRequired(UpsertInternalUser);
