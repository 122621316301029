import { Breadcrumb } from '@fe/breadcrumb';
import { PageLayout } from '@fe/layout';
import { defaultTo } from 'ramda';
import { useParams } from 'react-router-dom';
import { useAppState } from '../app-context';
import { getUrlFromPartition } from '../constants';
import { MerchantUserDetails } from '../types';

export const UpsertMerchantUserHeader = ({
  user,
}: {
  user: MerchantUserDetails | null;
}) => {
  const { merchantId } = useParams();
  const { currentUser, brands } = useAppState();

  const brandPartition =
    currentUser!.type === 'INTERNAL'
      ? brands.find(b => b.id === merchantId)?.brandPartition
      : currentUser!.brandRoles.find(b => b.brandId === merchantId)?.partition;

  const partitionUrl = getUrlFromPartition(brandPartition || '');
  const breadcrumbItems = [
    {
      label: merchantId!,
      href: `${partitionUrl}v2/app/merchant-settings`,
    },
    { label: 'Users', href: `/merchants/${merchantId}/users/` },
    {
      label: defaultTo('Create User', user?.emailAddress),
    },
  ];

  return (
    <PageLayout.Header>
      <Breadcrumb items={breadcrumbItems} />
    </PageLayout.Header>
  );
};
