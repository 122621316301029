import { Label } from '@fe/label';
import classNames from 'classnames';
import { defaultTo } from 'ramda';
import { Fragment } from 'react';
import { useAppState } from '../app-context';
import { AssociatedBrand, Role } from '../types';

const roleDictionary: Record<Role, string> = {
  BASIC: 'Basic User',
  MERCHANT_ADMIN: 'Merchant Admin',
  MERCHANT_KEY_ADMIN: 'Merchant Key Admin',
};

export const DisplayUserBrands = ({
  className,
  brands,
}: {
  className?: string;
  brands: AssociatedBrand[];
}) => {
  const { brands: allBrands } = useAppState();
  return (
    <div className={classNames(className, 'w-full')}>
      <Label text="This user also has access to these brands" />
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-1 justify-between xl:border border-1 border-grey-davys-50 w-full rounded-md min-w-[325px]">
        <div className="text-xs pl-2 pt-2">Brand ID</div>
        <div className="text-xs pl-2 pt-2 xl:pl-0">Role</div>
        {brands.map(({ brandId, role }, index, arr) => (
          <Fragment key={`brand-${brandId}-${role}`}>
            <div
              className={classNames({
                'xl:col-span-2 border-t border-t-1': index === 0,
              })}
            />
            <div className="xl:col-start-1 pl-2 pb-1">
              <span className="xl:invisible xl:absolute">Brand: </span>
              <div>
                {defaultTo(
                  brandId,
                  allBrands.find(({ id }) => id === brandId)?.name
                )}
              </div>
              <div>({brandId})</div>
            </div>
            <div className="pl-2 xl:pl-0">
              <span className="xl:invisible">Role: </span>
              <span>{defaultTo(role, roleDictionary[role])}</span>
            </div>
            <div
              className={classNames({
                'xl:col-span-2 border-b border-b-1': arr.length - 1 !== index,
                'mb-1': arr.length - 1 === index,
              })}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};
