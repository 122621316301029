export const PortalLinks = {
  UTF8: `${window.__env.portalBaseUrls.UTF8}/receipt/`,
  LATIN1: `${window.__env.portalBaseUrls.LATIN1}/receipt/`,
  LOCAL: `${window.__env.portalBaseUrls.LOCAL}/receipt/`,
};

export const pageSizeOptions = [10, 20, 30, 40]

export function getUrlFromPartition(partition: string) {
  return Object.values(PortalLinks)[ Object.keys(PortalLinks).indexOf(partition) ]
}