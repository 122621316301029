import { Route, Routes } from 'react-router-dom';
import App from './App';
import Redirect from './Redirect';
import NotFoundPage from './components/NotFoundPage';
import AccountPicker from './pages/AccountPicker';
import AdminUsers from './pages/AdminUsers';
import Registration from './pages/Registration';
import Logout from './pages/Logout';
import UpsertInternalUser from './pages/UpsertInternalUser';
import UserMigrated from './pages/UserMigrated';
import MerchantUsers from './pages/MerchantUsers';
import UpsertMerchantUser from './pages/UpsertMerchantUser';

export default function AppRoutes() {
  return (
    <div className="mx-8">
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="account-picker" element={<AccountPicker />} />
          <Route path="user-migrated" element={<UserMigrated />} />
          <Route
            path="registration/:verificationToken"
            element={<Registration />}
          />
          <Route path="admin/users" element={<AdminUsers />} />
          <Route path="admin/users/new" element={<UpsertInternalUser />} />
          <Route path="admin/users/:userId" element={<UpsertInternalUser />} />
          <Route
            path="merchants/:merchantId/users"
            element={<MerchantUsers />}
          />
          <Route
            path="merchants/:merchantId/users/new"
            element={<UpsertMerchantUser />}
          />
          <Route
            path="merchants/:merchantId/users/:userId"
            element={<UpsertMerchantUser />}
          />
          <Route path="redirect" element={<Redirect />} />
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}
