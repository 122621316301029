import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

export const Auth0ProviderWithHistory = ({
  children,
}: {
  children: ReactNode;
}) => {
  const authConfig = window.__env.authConfig;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      {...authConfig}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback as any}
    >
      {children}
    </Auth0Provider>
  );
};
