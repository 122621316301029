import { TwoPanelLayout } from '@fe/layout';
import { AuthHeader } from '@identity/components';
import { useSearchParams } from 'react-router-dom';

export default function UserMigrated() {
  const [searchParams] = useSearchParams();

  return (
    <TwoPanelLayout>
      <TwoPanelLayout.LeftPanel>
        <AuthHeader logo={`${process.env.PUBLIC_URL}/logo.png`} />
        <div className="mt-6 text-base">
          <p>
            We're updating our login process to keep you secure and provide new
            features. Here's what to expect:
          </p>
          <ol className="list-disc pl-8 mt-1">
            <li>
              You will receive a registration link within the next few minutes
              to your email {searchParams.get('email')}.
            </li>
            <li>
              Use this link to register your account and set a new password.
            </li>
            <li>
              <>If you don't receive and email, contact </>
              <a
                className="text-orange-500 hover:text-orange-700 transition duration-150 ease-in-out"
                href="mailto:support@flexengage.com"
              >
                support@flexengage.com
              </a>
            </li>
          </ol>
        </div>
      </TwoPanelLayout.LeftPanel>
      <TwoPanelLayout.RightPanel>
        <h1 className="text-white m-0 font-normal text-6xl">
          Let's get started
        </h1>
      </TwoPanelLayout.RightPanel>
    </TwoPanelLayout>
  );
}
